<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <Toast
          v-if="toastData.show"
          :type="toastData.type"
          :message="toastData.message"
          :show="toastData.show"
          :time="10000"
          @clearToast="clearToast"
        />
        <div class="container-fluid">
          <!-- topbar -->
          <div class="d-flex justify-content-between pb-5">
            <div>
              <ul
                class="breadcrumb ProximaNovaRegular"
                style="margin-top: 10px; margin-left: 10px"
              >
                <li>
                  <span>Solutions</span>
                </li>
                <li>
                  <router-link to="/solutions/health-monitor">
                    <span>Health Monitor</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/solutions/unified-campaign">
                    <span>{{ planName }}</span>
                  </router-link>
                </li>
                <li>
                  <span
                    @click="
                      $router.push({
                        name: 'Channel Level Dashboard',
                        params: { channel: channel },
                      })
                    "
                    >{{ channel }}</span
                  >
                </li>
                <li class="ProximaNovaBold">
                  {{ adType }}
                </li>
              </ul>
            </div>
            <div>
              <div>
                {{ startDate }} -
                {{ endDate }}
              </div>
              <date-picker
                v-model="customDate"
                class="date-picker"
                ref="datePicker"
                format="MMM YYYY"
                range
                confirm
                confirm-text="Done"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></date-picker>
              <w-white-dropdown
                :options="filterDropdown"
                :placeHolder="'Select'"
                :selectedOption="selectedFilter"
                :labelText="''"
                @input="selectFilter($event)"
                width="178px"
              ></w-white-dropdown>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-12">
              <div class="card">
                <div
                  class="wrapper content-center px-3 pt-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Metrics</h5>
                  <div class="" style="cursor: pointer">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedMetrics"
                      :options="metricDropList"
                      :maximum="4"
                      @selectedOptions="getSelectedMetrics($event)"
                      ref="metricUpdate"
                    />
                  </div>
                </div>
                <b-container fluid>
                  <b-row class="px-3 mt-5" v-if="metricData.length !== 0">
                    <div
                      class="col-sm-6 col-lg-3 mb-4"
                      v-for="(metric, index) in metricData"
                      :key="index"
                    >
                      <div class="card">
                        <div class="metrics-card-heading">
                          {{ metric.title }}
                        </div>
                        <div
                          class="d-flex justify-content-between p-4 border-top"
                        >
                          <ComparedStats
                            :value="metric.value"
                            :unit="metric.unit"
                            :type="metric.type"
                            :percentage="metric.percentage"
                          />
                          <ComparedLine
                            v-if="metric.chartType === 'line'"
                            height="70"
                            width="150"
                            :lineData="metric.lineData"
                            :colors="['#faacfe']"
                          />
                          <ComparedColumn
                            v-else
                            height="70"
                            width="150"
                            :columnData="metric.columnData"
                          />
                        </div>
                      </div>
                    </div>
                  </b-row>
                  <div
                    class="text-center font-medium w-100 ProximaNovaSemiBold"
                    v-html="metricNoData"
                    v-else
                  ></div>
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="metricStatus === 'error'"
                  >
                    <div
                      @click="getMetricData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                </b-container>
              </div>
            </div>

            <!-- Yesterday's Performance -->
            <div class="col-12 col-lg-7 mt-5">
              <div class="card h-100 overflow-auto">
                <div
                  class="wrapper content-center px-3 pt-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">{{ endDate }} Performance</h5>
                  <div class="" style="cursor: pointer">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedYesterday"
                      :options="metricDropList"
                      :maximum="5"
                      @selectedOptions="getSelectedYesterday($event)"
                      ref="yesterdayUpdate"
                    />
                  </div>
                </div>
                <PerformanceTable
                  v-if="yesterdayPerformanceData.length !== 0"
                  class="mt-5 performance-table h-100"
                  :items="yesterdayPerformanceData"
                />
                <div
                  class="text-center content-center h-75 font-medium w-100 ProximaNovaSemiBold"
                  style="min-height:100px;"
                  v-html="ystNoData"
                  v-else
                ></div>
                <div
                  class="content-center ProximaNovaSemiBold"
                  v-if="ystPStatus === 'error'"
                >
                  <div
                    @click="getYesterdayData"
                    class="content-center refreshButton"
                  >
                    <span class="pr-3 font-medium">Retry</span
                    ><span class="refreshIcon"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Usage - Device Type -->
            <div class="col-12 col-lg-5 mt-5">
              <div class="card pb-5 pb-lg-0 h-100">
                <div
                  class="wrapper content-center pt-4 mb-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Usage - Device Type</h5>
                  <div class="pr-4" style="cursor: pointer">
                    <w-single-dropdown
                      :options="campaignDropList"
                      :value="selectedDeviceType"
                      @selectedOptions="getSelectedDeviceType($event)"
                    />
                  </div>
                </div>
                <div>
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="deviceTypeStatus === 'error'"
                  >
                    <div
                      @click="getDeviceTypeData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                  <DonutChart :chartData="usageData" ref="deviceTypeLoading" />
                </div>
              </div>
            </div>

            <!-- Top Location -->
            <div class="col-12 col-lg-12 mt-5">
              <div class="card pb-5 pb-lg-0">
                <div
                  class="wrapper content-center pt-4 mb-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Top Location</h5>
                  <div class="pr-4" style="cursor: pointer">
                    <w-single-dropdown
                      :options="campaignDropList"
                      :value="selectedTopLoaction"
                      @selectedOptions="getSelectedTopLoaction($event)"
                    />
                  </div>
                </div>
                <div class="p-4">
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="topLocationStatus === 'error'"
                  >
                    <div
                      @click="getTopLoactionData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                  <ColumnChart
                    :chartData="regionWiseData"
                    ref="topLocationLoading"
                    :annotation="topLocationAnnotation"
                  />
                </div>
              </div>
            </div>
            <!-- Top Campaign Group -->
            <div class="col-12 col-lg-6 mt-5">
              <div class="card pb-5 pb-lg-0">
                <div
                  class="wrapper content-center pt-4 mb-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Top Campagins</h5>
                  <div class="pr-4" style="cursor: pointer">
                    <w-single-dropdown
                      :options="campaignDropList"
                      :value="selectedTopCompaign"
                      @selectedOptions="getSelectedTopCampaign($event)"
                    />
                  </div>
                </div>
                <div class="p-4">
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="topCampaignStatus === 'error'"
                  >
                    <div
                      @click="getCompaignData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                  <ColumnChart
                    :chartData="topCampaignData"
                    ref="topCampaignLoading"
                    :annotation="topCampaignAnnotation"
                  />
                </div>
              </div>
            </div>
            <!-- Top Ad Group -->
            <div class="col-12 col-lg-6 mt-5">
              <div class="card p-4">
                <div
                  class="wrapper content-center pt-4 mb-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Top Ad Groups</h5>
                  <div class="" style="cursor: pointer">
                    <w-single-dropdown
                      :options="campaignDropList"
                      :value="selectedTopAdGroup"
                      @selectedOptions="getSelectedTopAdGroup($event)"
                    />
                  </div>
                </div>
                <div
                  class="content-center ProximaNovaSemiBold"
                  v-if="topAdGroupStatus === 'error'"
                >
                  <div
                    @click="getTopAdGroupData"
                    class="content-center refreshButton"
                  >
                    <span class="pr-3 font-medium">Retry</span
                    ><span class="refreshIcon"></span>
                  </div>
                </div>
                <BarChart
                  :chartData="socialBarData"
                  ref="topAdGroupLoading"
                  :annotation="topAdGroupAnnotation"
                />
              </div>
            </div>

            <!-- Campaign Details -->
            <div class="col-12 mt-5 mb-5">
              <HealthMonitorTable
                :items="detailsData"
                :title="'Campaign Details'"
                @enableCompare="comparePlans = true"
                :campaignCurrentPage="currentPage"
                :campaignTableData="campaignTableData"
                :campaignPerPage="perPage"
                :campaignTotoalRows="totalRows"
                :fields="campaignTableFields"
                :campaigntableLoading="campaigntableLoading"
                @getCampaignPageEvent="getCampaignPageEvent($event)"
                @getAdGroupTableData="getAdGroupTableData($event)"
                :adGroupCurrentPage="currentPageAdGroup"
                :adGroupPerPage="rowsPrePageAdGroup"
                :adGroupTableLoading="adGroupTableLoading"
                :adGroupTotoalRows="totalRowsAdGroup"
                :adGroupFields="adGroupTableFields"
                :adGroupTableData="adGroupTableData"
                :adTypeTableData="adTypeTableData"
                :adTypeCurrentPage="currentPageAdType"
                :adTypeTableLoading="adTypeTableLoading"
                :adTypePerPage="rowsPrePageAdType"
                :adTypeTotoalRows="totalRowsAdType"
                :adTypeFields="adTypeTableFields"
                @getAdGroupPageEvent="getAdGroupPageEvent($event)"
                @getAdTypeTableData="getAdTypeTableData($event)"
                @getAdTypePageEvent="getAdTypePageEvent($event)"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
// import Location from "@/components/HealthMonitor/Location.vue";
// import Campaign from "@/components/HealthMonitor/Campaign.vue";
import HealthMonitorTable from "@/components/Table/HealthMonitorTable.vue";
import MultiDropDown from "@/widgets/MultiDropDown.vue";
import ComparedStats from "@/components/SavedPlans/ComparedStats.vue";
import ComparedLine from "@/components/SavedPlans/ComparedLine.vue";
import ComparedColumn from "@/components/SavedPlans/ComparedColumn.vue";
import PerformanceTable from "@/components/Table/PerformanceTable.vue";
import DonutChart from "@/components/Chart/DonutChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
// import Dropdown from "@/widgets/Dropdown.vue";
import SingleDropdownCheckbox from "@/widgets/SingleDropdownCheckbox.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import { HealthMonitor } from "@/services/HealthMonitorServices.js";
import { abbreviateNumber } from "js-abbreviation-number";
import Toast from "@/components/Toast/Toast.vue";
const healthMonitor = new HealthMonitor();
import moment from "moment";
import DatePicker from "vue2-datepicker";
export default {
  name: "Channel_Wise_AdType_DSv",
  components: {
    DatePicker,
    "w-multiDropDown": MultiDropDown,
    "w-white-dropdown": WhiteDropdown,
    "w-single-dropdown": SingleDropdownCheckbox,
    // Location,
    // Campaign,
    HealthMonitorTable,
    ComparedStats,
    ComparedLine,
    ComparedColumn,
    Toast,
    PerformanceTable,
    DonutChart,
    BarChart,
    // "w-dropdown": Dropdown,
    ColumnChart,
  },
  data() {
    return {
      metricNoData: "",
      metricStatus: "initial",
      dataStartDate: "",
      errorTime: "",
      rateMetrics: ["VTR", "CTR", "conversions_rate", "Engagements_rate"],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      adType: "",
      adGroupTableFields: [
        // {
        //   key: "campaignname",
        //   label: "Campaign Name",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          key: "Adgroup Name",
          label: "AdGroup Name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   key: "adtype",
        //   label: "Ad_type",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          key: "impressions",
          label: "Impressions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "clicks",
          label: "Clicks",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "ctr",
          label: "CTR",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          key: "cpc",
          label: "CPC",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cpa",
          label: "CPA",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions_rate",
          label: "Conv Rate %",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions",
          label: "Conversions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cost",
          label: "Cost",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      adGroupTableData: [],
      adGroupTableLoading: false,
      currentPageAdGroup: 1,
      rowsPrePageAdGroup: 10,
      totalRowsAdGroup: 0,

      adTypeTableFields: [
        // {
        //   key: "keyword",
        //   label: "Keywords",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          key: "adtype",
          label: "Ad_type",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "impressions",
          label: "Impressions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "clicks",
          label: "Clicks",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "ctr",
          label: "CTR",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          key: "cpc",
          label: "CPC",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cpa",
          label: "CPA",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions_rate",
          label: "Conv Rate %",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions",
          label: "Conversions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cost",
          label: "Cost",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      adTypeTableData: [],
      adTypeTableLoading: false,
      currentPageAdType: 1,
      rowsPrePageAdType: 10,
      totalRowsAdType: 0,
      currentPage: 1,
      campaignTableData: [],
      campaigntableLoading: false,
      perPage: 10,
      totalRows: 0,
      campaignTableFields: [
        {
          key: "campaignname",
          label: "Campaign Name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   key: "adtype",
        //   label: "Ad_type",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          key: "impressions",
          label: "Impressions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "clicks",
          label: "Clicks",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "ctr",
          label: "CTR",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          key: "cpc",
          label: "CPC",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cpa",
          label: "CPA",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions_rate",
          label: "Conv Rate %",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "conversions",
          label: "Conversions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cost",
          label: "Cost",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      campaignDropList: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Video Views", value: "Video_views" },
        { name: "CTR", value: "CTR" },
        { name: "Conversions", value: "Conversions" },
        { name: "Conv Rate%", value: "conversions_rate" },
        { name: "Cost", value: "Cost" },
        // { name: "Cost_conv", value: "Cost Conv" },
        // { name: "Video View Rate", value: "Video_view" },
        { name: "Engagements", value: "Engagements" },
        { name: "Engagements_rate", value: "Engagements_rate" },
        // { name: "CPL", value: "CPL" },
        { name: "CPA", value: "CPA" },
        { name: "CPC", value: "CPC" },
        { name: "CPV", value: "CPV" },
        { name: "CPE", value: "CPE" },
        { name: "CPM", value: "CPM" },
        { name: "VTR", value: "VTR" },
      ],
      selectedTopAdGroup: {},
      selectedTopLoaction: {},
      selectedTopCompaign: {},
      selectedDeviceType: {},
      customDate: [],
      activeCampaignType: "Post Promotions",
      selectedFilter: { text: "Date", id: "" },
      selectedYesterday: [],
      selectedMetrics: [],
      metricData: [],
      metricDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video Views", value: "Video_views", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "Conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "Cost", $isDisabled: false },
        // { name: "Cost_conv", value: "Cost Conv", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        // { name: "CPL", value: "CPL", $isDisabled: false },
        { name: "CPA", value: "CPA", $isDisabled: false },
        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
      ],
      detailsData: [],
      // new records
      performanceInitialOptionsSelected: [
        { name: "Clicks", value: false, $isDisabled: false },
        { name: "Conversions", value: false, $isDisabled: false },
        { name: "CTR", value: true, $isDisabled: true },
      ],
      lineData: [
        {
          data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 3, 3, 2, 1, 4],
          marker: {
            symbol: "circle",
            radius: 10,
            status: {
              hover: {
                enabled: true,
              },
            },
          },
          type: "spline",
          name: "",
        },
      ],
      columnData: [
        {
          name: "",
          data: [75, 100, 23, 42, 23, 23, 32, 23],
        },
      ],
      yesterdayPerformanceData: [],
      ystPStatus: "initial",
      ystNoData: "",
      deviceTypeStatus: "initial",
      usageData: {
        colors: ["#ffdd7c", "#faacfe"],
        title: {
          text: "",
          style: { "font-weight": "bold", fontSize: "20px", color: "#222a37" },
          align: "left",
          margin: 50,
        },
        series: [
          {
            name: "Devices",
            innerSize: "80%",
            size: "70%",
            data: [
              {
                name: "Mobile",
                y: 23,
                color: "#ffce40",
              },
              { name: "Desktop", y: 77, color: "rgba(181, 169, 255, 0.5)" },
            ],
            showInLegend: true,
            dataLabels: {
              enabled: false,
            },
          },
          {
            name: "Sub devices",
            innerSize: "70%",
            size: "100%",
            data: [
              {
                name: "Android",
                y: 12,
                color: "#ffdd7c",
              },
              { name: "iPhone", y: 11, color: "rgba(255, 221, 124, 0.5)" },
              { name: "Desktop", y: 77, color: "rgba(181, 169, 255, 0.5)" },
            ],
            dataLabels: {
              enabled: true,
              connectorColor: "black",
              connectorShape: "crookedLine",
              crookDistance: "100%",
              annotation: "",
              distance: 10,
              y: -10,

              formatter: function(data) {
                return `<span style="color:#8394b4; font-size:14px; font-family:ProximaNovaRegular">${
                  this.point.name
                }</span>
               <br>${abbreviateNumber(this.y, 1, {
                 symbols: ["", "k", "M", "B", "T", "P", "E"],
               }) + data.annotation}`;
              },
              style: {
                fontWeight: "bold",
                color: "black",
                fontSize: "14px",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          accessibility: {
            enabled: true,
          },
          symbolPadding: 10,
          symbolHeight: 15,
          itemMarginBottom: 10,
          itemStyle: {
            color: "#8394b4",
            fontWeight: "bold",
            fontSize: "12px",
          },
          enabled: true,
          width: "450",
        },
      },
      options: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Engagements", value: "Engagements" },
        { name: "Video View", value: "Video_view" },
        { name: "Conversions", value: "Conversions" },
        { name: "Conv Value", value: "Conv_value" },
      ],
      value: {
        name: "Conversions",
        value: "Conversions",
      },
      campaignTypeData: {
        height: 340,
        categories: [
          "Leadcards",
          "Post Promotions",
          "Video",
          "Display",
          "Social",
        ],
        colors: ["#81f89e", "#67e8ff"],
        series: [
          {
            name: "Conversions",
            data: [5100, 12000, 7000, 12000, 6000],
          },
          {
            name: "Cost",
            data: [5000, 12000, 7000, 12000, 6000],
          },
        ],
      },
      topAdGroupAnnotation: "",
      topAdGroupStatus: "initial",
      socialBarData: {
        height: 400,
        scrollableHeight: 450,
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
            labels: {
              style: {
                color: "#a9b6cd",
                fontSize: "12px",
                fontFamily: "ProximaNovaRegular",
              },
            },
            gridLineDashStyle: "shortdash",
          },
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0.3,
            colorByPoint: true,
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: [],
          },
        ],
      },
      filterDropdown: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "Last 7 Days" },
        { text: "Last 30 Days", id: "Last 30 Days" },
        { text: "This Month", id: "This Month" },
        { text: "Last Month", id: "Last Month" },
        { text: "This Year", id: "This Year" },
        { text: "Last Year", id: "Last Year" },
        { text: "Custom", id: "customSelect" },
      ],
      summaryOptionsSelected: [
        { name: "Impressions", value: true, $isDisabled: false },
        { name: "Ctr", value: false, $isDisabled: true },
        { name: "Clicks", value: true, $isDisabled: false },
        { name: "Cpc", value: false, $isDisabled: false },
        { name: "Conversions", value: true, $isDisabled: false },
        { name: "VideoViews", value: true, $isDisabled: false },
        { name: "Engagements", value: false, $isDisabled: false },
        { name: "Costs", value: false, $isDisabled: false },
        { name: "Cpm", value: false, $isDisabled: false },
      ],
      topCampaignAnnotation: "",
      topCampaignData: {
        scrollableWidth: 650,
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#faacfe", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [
            "Adgroup_1",
            "Adgroup_2",
            "Adgroup_3",
            "Adgroup_4",
            "Adgroup_5",
          ],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      topCampaignStatus: "initial",
      topLocationAnnotation: "",
      topLocationStatus: "initial",
      regionWiseData: {
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#faacfe", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: false,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              annotation: "",
              enabled: true,
              formatter: function(data) {
                return (
                  abbreviateNumber(this.y, 1, {
                    symbols: ["", "k", "M", "B", "T", "P", "E"],
                  }) + data.annotation
                );
              },
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      channel: "",
      planName: "",
      orgNumber: "",
      campaignId: "",
      objective: "",
      startDate: "",
      endDate: "",
      dataEndDate: "",
    };
  },
  mounted() {
    this.dataStartDate = sessionStorage.getItem("dataStartDate");
    this.dataEndDate = sessionStorage.getItem("dataEndDate");
    this.channel = sessionStorage.getItem("channel");
    this.planName = sessionStorage.getItem("planName");
    this.orgNumber = sessionStorage.getItem("subId");
    this.campaignId = sessionStorage.getItem("planId");
    this.objective = sessionStorage.getItem("Objective");
    this.startDate = sessionStorage.getItem("startDate");
    this.endDate = sessionStorage.getItem("endDate");
    this.adType = sessionStorage.getItem("adType");
    this.selectedFilter = JSON.parse(sessionStorage.getItem("selectedDate"));
    this.customDate = [new Date(this.startDate), new Date(this.endDate)];
    this.getObjectiveWiseData();
    // this.$refs.updateCampaignTypeData.updateData()
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date(this.dataStartDate);
      today.setHours(0, 0, 0, 0);
      return date < today || date > new Date(this.dataEndDate);
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    errorToast(e) {
      clearTimeout(this.errorTime);
      var self = this;
      if (e === undefined) {
        this.errorTime = setTimeout(function() {
          self.toastData.show = true;
          self.toastData.type = "failed";
          self.toastData.message =
            "Something went wrong please try after sometime";
        }, 3000);
      } else {
        this.errorTime = setTimeout(function() {
          self.toastData.show = true;
          self.toastData.type = "failed";
          self.toastData.message = e;
        }, 3000);
      }
    },
    updateCampaignType(val) {
      this.activeCampaignType = val;
    },
    selectFilter(e) {
      if (this.selectedFilter === e && e.text !== "Custom") {
        return;
      }
      this.selectedFilter = e;
      var startDateLocal;
      var endDateLocal;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
        return;
      } else if (e && e.id === "today") {
        startDateLocal = new Date(this.dataEndDate);
        endDateLocal = new Date(this.dataEndDate);
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date(this.dataEndDate);
        dateObj.setDate(dateObj.getDate() - 1);
        startDateLocal = dateObj;
        endDateLocal = dateObj;
      } else if (e && e.id === "Last 7 Days") {
        startDateLocal = new Date(
          new Date(this.dataEndDate).setDate(
            new Date(this.dataEndDate).getDate() - 6
          )
        );
        endDateLocal = new Date(this.dataEndDate);
      } else if (e && e.id === "Last 30 Days") {
        startDateLocal = new Date(
          new Date(this.dataEndDate).setDate(
            new Date(this.dataEndDate).getDate() - 30
          )
        );
        endDateLocal = new Date(this.dataEndDate);
      } else if (e && e.id === "This Month") {
        var today = new Date(this.dataEndDate);
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 0, 1);
        endDateLocal = new Date(this.dataEndDate);
      } else if (e && e.id === "Last Month") {
        today = new Date(this.dataEndDate);
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDateLocal = new Date(today.getFullYear(), today.getMonth(), 0);
      } else if (e && e.id === "This Year") {
        today = new Date(this.dataEndDate);
        startDateLocal = new Date(today.getFullYear(), 0, 1);
        endDateLocal = today;
      } else if (e && e.id === "Last Year") {
        today = new Date(this.dataEndDate);
        startDateLocal = new Date(
          new Date(this.dataEndDate).getFullYear(),
          -11,
          -30
        );
        endDateLocal = new Date(new Date(this.dataEndDate).getFullYear(), 0, 0);
      }
      this.startDate = moment(startDateLocal).format("YYYY-MM-DD");
      this.endDate = moment(endDateLocal).format("YYYY-MM-DD");
      this.edited = true;
      sessionStorage.setItem("startDate", this.startDate);
      sessionStorage.setItem("endDate", this.endDate);
      sessionStorage.setItem(
        "selectedDate",
        JSON.stringify(this.selectedFilter)
      );
      this.customDate = [new Date(this.startDate), new Date(this.endDate)];
      this.getObjectiveWiseData();
    },
    getSelectedMetrics(e) {
      this.selectedMetrics = e;
      this.getMetricData();
    },
    getMetricData() {
      var metric = [];
      for (var j = 0; j < this.selectedMetrics.length; j++) {
        metric.push(this.selectedMetrics[j].value);
      }
      this.metricData = [];
      this.metricNoData = `<div><div class='Budgetloader'></div>Loading...</div>`;
      this.metricStatus = "loading";
      healthMonitor
        .getChannelSalesPerformace(
          this.orgNumber,
          this.campaignId,
          metric,
          this.startDate,
          this.endDate,
          this.channel,
          this.adType
        )
        .then((res) => {
          if (res.length === 0) {
            this.metricNoData = `<div>No Data Found</div>`;
            this.metricStatus = "No Data";
          }
          this.metricData = [];
          var type = "line";
          for (var i = 0; i < res.length; i++) {
            var mark = "";
            if (this.rateMetrics.includes(res[i].name)) {
              mark = "%";
            } else {
              mark = "";
            }
            this.metricData.push({
              title: res[i].name,
              lineData: [
                {
                  data: JSON.parse(res[i].data),
                  marker: {
                    symbol: "circle",
                    radius: 10,
                    status: {
                      hover: {
                        enabled: true,
                      },
                    },
                  },
                  type: "spline",
                  name: "",
                },
              ],
              columnData: [
                {
                  name: res[i].name,
                  data: JSON.parse(res[i].data),
                },
              ],
              chartType: type,
              value: res[i].value + mark,
              unit: "",
              type: res[i].status + "ward",
              percentage: res[i].difference,
            });
            if (type === "line") {
              type = "bar";
            } else {
              type = "line";
            }
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.metricStatus = "error";
          this.metricNoData = `<div>${e.message}</div>`;
        });
    },
    getSelectedYesterday(e) {
      this.selectedYesterday = e;
      this.getYesterdayData();
    },
    getYesterdayData() {
      var metric = [];
      for (var j = 0; j < this.selectedYesterday.length; j++) {
        metric.push(this.selectedYesterday[j].value);
      }
      var colors = ["#81f89e", "#ffa2a2", "#67e8ff", "#ffdd7c", "#b5a8ff"];
      this.ystPStatus = "Loading";
      this.ystNoData = "<div><div class='Budgetloader'></div>Loading...</div>";
      this.yesterdayPerformanceData = [];
      healthMonitor
        .getYesterdayPerformance(
          this.orgNumber,
          this.campaignId,
          metric,
          this.startDate,
          this.endDate,
          this.channel,
          this.adType
        )
        .then((res) => {
          if (res.length === 0) {
            this.ystNoData = "<div>NA</div>";
            this.ystPStatus = "No Data";
          }
          this.yesterdayPerformanceData = [];
          for (var i = 0; i < res.length; i++) {
            var differ = "";
            if (res[i].status === "Negative") {
              differ = "down";
            } else {
              differ = "up";
            }
            this.yesterdayPerformanceData.push({
              objectives: res[i].name,
              metricsDelivery: res[i].value,
              variance: {
                direction: differ,
                value: res[i].difference,
              },
              past10daysanalysis: [
                {
                  data: JSON.parse(res[i].data),
                  marker: {
                    symbol: "circle",
                    radius: 10,
                    status: {
                      hover: {
                        enabled: true,
                      },
                    },
                  },
                  type: "spline",
                  name: "",
                },
              ],
              categories: JSON.parse(res[i].dates),
              color: colors[i],
            });
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.ystPStatus = "error";
          this.ystNoData = `<div>${e.message}</div>`;
        });
    },
    getSelectedDeviceType(e) {
      this.selectedDeviceType = e;
      this.getDeviceTypeData();
    },
    getDeviceTypeData() {
      var colors = [
        "rgba(181, 169, 255)",
        "#81f89e",
        "#ffa2a2",
        "#67e8ff",
        "#ffdd7c",
        "#b5a8ff",
      ];
      this.usageData.series[0].data = [];
      this.deviceTypeStatus = "loading";
      this.usageData.series[1].data = [];
      this.$refs.deviceTypeLoading.$refs.donutChart.chart.showLoading(
        `<div><div class='Budgetloader'></div>Loading...</div>`
      );
      healthMonitor
        .getDeviceTypeUsage(
          this.orgNumber,
          this.campaignId,
          this.selectedDeviceType.value,
          this.channel,
          this.adType,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (this.rateMetrics.includes(this.selectedDeviceType.value)) {
            this.usageData.series[1].dataLabels.annotation = "%";
          } else {
            this.usageData.series[1].dataLabels.annotation = "";
          }
          if (res.length === 0) {
            this.$refs.deviceTypeLoading.$refs.donutChart.chart.showLoading(
              `<div>No Data Found</div>`
            );
            this.deviceTypeStatus = "No Data";
          } else {
            this.$refs.deviceTypeLoading.$refs.donutChart.chart.hideLoading();
          }
          this.usageData.series[0].data = [];
          this.usageData.series[1].data = [];
          for (var i = 0; i < res.length; i++) {
            this.usageData.series[0].data.push({
              name: res[i].name,
              y: Math.round(res[i].value),
              color: colors[i],
            });
            this.usageData.series[1].data.push({
              name: res[i].name,
              y: Math.round(res[i].value),
              color: colors[i],
            });
          }
        })
        .catch((e) => {
          this.deviceTypeStatus = "error";
          this.errorToast(e.message);
          this.$refs.deviceTypeLoading.$refs.donutChart.chart.showLoading(
            `<div>${e.message}</div>`
          );
        });
    },
    getSelectedTopLoaction(e) {
      this.selectedTopLoaction = e;
      this.getTopLoactionData();
    },
    getTopLoactionData() {
      this.regionWiseData.data[0].data = [];
      this.topLocationStatus = "Loading";
      this.regionWiseData.xAxis.categories = [];
      this.$refs.topLocationLoading.$refs.columnChart.chart.showLoading(
        `<div><div class='Budgetloader'></div>Loading...</div>`
      );

      healthMonitor
        .getRegionWisePerformance(
          this.orgNumber,
          this.campaignId,
          this.selectedTopLoaction.value,
          this.channel,
          this.adType,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (this.rateMetrics.includes(this.selectedTopLoaction.value)) {
            this.topLocationAnnotation = "%";
          } else {
            this.topLocationAnnotation = "";
          }
          if (res.length === 0) {
            this.$refs.topLocationLoading.$refs.columnChart.chart.showLoading(
              `<div>No Data Found</div>`
            );
            this.topLocationStatus = "No Data";
          } else {
            this.$refs.topLocationLoading.$refs.columnChart.chart.hideLoading();
          }
          this.regionWiseData.data[0].data = [];
          this.regionWiseData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.regionWiseData.data[0].data.push(Math.round(res[i].value));
            this.regionWiseData.xAxis.categories.push(res[i].name);
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.topLocationStatus = "error";
          this.$refs.topLocationLoading.$refs.columnChart.chart.showLoading(
            `<div>${e.message}</div>`
          );
        });
    },
    getSelectedTopCampaign(e) {
      this.selectedTopCompaign = e;
      this.getCompaignData();
    },
    getCompaignData() {
      this.topCampaignData.data[0].data = [];
      this.topCampaignStatus = "loading";
      this.topCampaignData.xAxis.categories = [];
      this.$refs.topCampaignLoading.$refs.columnChart.chart.showLoading(
        `<div><div class='Budgetloader'></div>Loading...</div>`
      );
      healthMonitor
        .getTopCampaign(
          this.orgNumber,
          this.campaignId,
          this.selectedTopCompaign.value,
          this.channel,
          this.adType,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (this.rateMetrics.includes(this.selectedTopCompaign.value)) {
            this.topCampaignAnnotation = "%";
          } else {
            this.topCampaignAnnotation = "";
          }
          if (res.length === 0) {
            this.$refs.topCampaignLoading.$refs.columnChart.chart.showLoading(
              `<div>No Data Found</div>`
            );
            this.topCampaignStatus = "No Data";
          } else {
            this.$refs.topCampaignLoading.$refs.columnChart.chart.hideLoading();
          }
          this.topCampaignData.data[0].data = [];
          this.topCampaignData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.topCampaignData.data[0].data.push(Math.round(res[i].value));
            this.topCampaignData.xAxis.categories.push(res[i].name);
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.topCampaignStatus = "error";
          this.$refs.topCampaignLoading.$refs.columnChart.chart.showLoading(
            `<div>${e.message}</div>`
          );
        });
    },
    getSelectedTopAdGroup(e) {
      this.selectedTopAdGroup = e;
      this.getTopAdGroupData();
    },
    getTopAdGroupData() {
      this.$refs.topAdGroupLoading.$refs.barChart.chart.showLoading(
        `<div><div class='Budgetloader'></div>Loading...</div>`
      );
      this.socialBarData.series[0].data = [];
      this.topAdGroupStatus = "loading";
      this.socialBarData.xAxis.categories = [];
      healthMonitor
        .getTopAdgroup(
          this.orgNumber,
          this.campaignId,
          this.selectedTopAdGroup.value,
          this.channel,
          this.adType,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (this.rateMetrics.includes(this.selectedTopAdGroup.value)) {
            this.topAdGroupAnnotation = "%";
          } else {
            this.topAdGroupAnnotation = "";
          }
          if (res.length === 0) {
            this.$refs.topAdGroupLoading.$refs.barChart.chart.showLoading(
              `<div>No Data Found</div>`
            );
            this.topAdGroupStatus = "No data";
          } else {
            this.$refs.topAdGroupLoading.$refs.barChart.chart.hideLoading();
          }
          this.socialBarData.series[0].data = [];
          this.socialBarData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.socialBarData.series[0].data.push(Math.round(res[i].value));
            this.socialBarData.xAxis.categories.push(res[i].name);
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.topAdGroupStatus = "error";
          this.$refs.topAdGroupLoading.$refs.barChart.chart.showLoading(
            `<div>${e.message}</div>`
          );
        });
    },
    getObjectiveWiseData() {
      if (this.objective === "Conversions") {
        this.selectedMetrics = [
          { name: "Conversions", value: "Conversions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CPA", value: "CPA", $isDisabled: false },
        ];
        this.selectedYesterday = [
          { name: "Conversions", value: "Conversions", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "CPC", value: "CPC", $isDisabled: false },
        ];
        this.selectedDeviceType = { name: "Conversions", value: "Conversions" };
        this.selectedTopLoaction = {
          name: "Conversions",
          value: "Conversions",
        };
        this.selectedTopCompaign = {
          name: "Conversions",
          value: "Conversions",
        };
        this.selectedTopAdGroup = {
          name: "Conversions",
          value: "Conversions",
        };
      } else if (this.objective === "Impressions") {
        this.selectedMetrics = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CPA", value: "CPA", $isDisabled: false },
        ];
        this.selectedYesterday = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "CPM", value: "CPM", $isDisabled: false },
        ];
        this.selectedDeviceType = {
          name: "Impressions",
          value: "Impressions",
        };
        this.selectedTopLoaction = {
          name: "Impressions",
          value: "Impressions",
        };
        this.selectedTopCompaign = {
          name: "Impressions",
          value: "Impressions",
        };
        this.selectedTopAdGroup = {
          name: "Impressions",
          value: "Impressions",
        };
      } else if (this.objective.toLowerCase() === "Video Views".toLowerCase()) {
        this.selectedMetrics = [
          { name: "Video Views", value: "Video_views", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CPV", value: "CPV", $isDisabled: false },
        ];
        this.selectedYesterday = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Video Views", value: "Video_views", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "VTR", value: "VTR", $isDisabled: false },
          { name: "CPV", value: "CPV", $isDisabled: false },
        ];
        this.selectedDeviceType = {
          name: "Video Views",
          value: "Video_views",
        };
        this.selectedTopLoaction = {
          name: "Video Views",
          value: "Video_views",
        };
        this.selectedTopCompaign = {
          name: "Video Views",
          value: "Video_views",
        };
        this.selectedTopAdGroup = {
          name: "Video Views",
          value: "Video_views",
        };
      } else if (this.objective === "Engagements") {
        this.selectedMetrics = [
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CPE", value: "CPE", $isDisabled: false },
        ];
        this.selectedYesterday = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          {
            name: "Engagements_rate",
            value: "Engagements_rate",
            $isDisabled: false,
          },

          { name: "CPE", value: "CPE", $isDisabled: false },
        ];
        this.selectedDeviceType = {
          name: "Engagements",
          value: "Engagements",
        };
        this.selectedTopLoaction = {
          name: "Engagements",
          value: "Engagements",
        };
        this.selectedTopCompaign = {
          name: "Engagements",
          value: "Engagements",
        };
        this.selectedTopAdGroup = {
          name: "Engagements",
          value: "Engagements",
        };
      } else if (this.objective === "Clicks") {
        this.selectedMetrics = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Conversions", value: "Conversions", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CPC", value: "CPC", $isDisabled: false },
        ];
        this.selectedYesterday = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Cost", value: "Cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "CPC", value: "CPC", $isDisabled: false },
        ];
        this.selectedDeviceType = { name: "Clicks", value: "Clicks" };
        this.selectedTopLoaction = { name: "Clicks", value: "Clicks" };
        this.selectedTopCompaign = { name: "Clicks", value: "Clicks" };
        this.selectedTopAdGroup = { name: "Clicks", value: "Clicks" };
      }
      this.$refs.yesterdayUpdate.updateData(this.selectedYesterday);
      this.$refs.metricUpdate.updateData(this.selectedMetrics);
      this.callAllApi();
    },
    getCampaignPageEvent(e) {
      if (e !== this.currentPage) {
        this.currentPage = e;
        this.getCampaignTableData();
      }
    },
    getCampaignTableData() {
      this.campaigntableLoading = true;
      this.campaignTableData = [];
      healthMonitor
        .getTableData(
          this.campaignId,
          this.orgNumber,
          "",
          this.channel,
          this.adType,
          this.startDate,
          this.endDate,
          this.currentPage,
          this.perPage
        )
        .then((res) => {
          var data = JSON.parse(res.response);
          this.totalRows = data.total_element;
          this.campaigntableLoading = false;
          this.campaignTableData = JSON.parse(data.content);
        })
        .catch((e) => {
          this.campaigntableLoading = false;
          this.errorToast(e.message);
        });
    },
    getAdGroupTableData(e) {
      this.adGroupTableLoading = true;
      this.adGroupTableData = [];
      healthMonitor
        .getAdGroupTableData(
          { requiredCampaignName: e.campaignname },
          this.campaignId,
          this.orgNumber,
          "",
          this.channel,
          this.adType,
          this.startDate,
          this.endDate,
          this.currentPageAdGroup,
          this.rowsPrePageAdGroup
        )
        .then((res) => {
          var data = JSON.parse(res.response);
          this.adGroupTableLoading = false;
          this.adGroupTableData = JSON.parse(data.content);
          this.totalRowsAdGroup = data.total_element;
        })
        .catch((e) => {
          this.adGroupTableLoading = false;
          this.errorToast(e.message);
        });
    },
    getAdGroupPageEvent(e) {
      if (e !== this.currentPageAdGroup) {
        this.currentPageAdGroup = e;
        this.getAdGroupTableData({});
      }
    },
    getAdTypePageEvent(e) {
      if (e !== this.currentPageAdType) {
        this.currentPageAdType = e;
        this.getAdTypeTableData({});
      }
    },
    getAdTypeTableData(e) {
      this.adTypeTableLoading = true;
      this.adTypeTableData = [];
      healthMonitor
        .getAdGroupTableData(
          {
            requiredAdgroupName: e["Adgroup Name"],
            requiredCampaignName: e.campaignname,
          },
          this.campaignId,
          this.orgNumber,
          "",
          this.channel,
          this.adType,
          this.startDate,
          this.endDate,
          this.currentPageAdType,
          this.rowsPrePageAdType
        )
        .then((res) => {
          this.adTypeTableLoading = false;
          var data = JSON.parse(res.response);
          this.adTypeTableData = JSON.parse(data.content);
          this.totalRowsAdType = data.total_element;
        })
        .catch((e) => {
          this.adTypeTableLoading = false;
          this.errorToast(e.message);
        });
    },
    callAllApi() {
      this.getMetricData();
      this.getYesterdayData();
      this.getDeviceTypeData();
      this.getTopLoactionData();
      this.getCompaignData();
      this.getTopAdGroupData();
      this.getCampaignTableData();
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1, {
            symbols: ["", "k", "M", "B", "T", "P", "E"],
          });
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
  },
  watch: {
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
        sessionStorage.setItem("endDate", this.endDate);
        sessionStorage.setItem("startDate", this.startDate);
        this.getObjectiveWiseData();
      }
    },
  },
};
</script>

<style scoped>
.card-heading {
  margin: 20px 22px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}

.metrics-card-heading {
  padding: 16px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
}

/* .chart-container {
  max-height: 375px;
  padding: 0;
} */

.page-wrapper {
  margin-top: 81px;
}
h1.usage-title {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}
.usage-dropdown {
  width: 150px !important;
}
/* >>> g.highcharts-axis-labels.highcharts-xaxis-labels > text {
  font-family: ProximaNovaRegular;
  font-size: 14px !important;
} */
.date-picker {
  width: 150px;
  position: absolute;
  right: 12px;
}
</style>
